//
// Prototype styles (using the Sass CSS preprocessor)
//

//
// First, we're importing the design system from the npm package
//
$static-font-path: "/assets/fonts/webfonts";

@import "node_modules/@salesforce-ux/design-system/scss/index";

//
// Then, we're importing some styles via Sass partials (filenames starting with a "_")
//
@import "footer";

//
// We can also add styles directly in this file, like so:
//

.landing {
  background-color: #fff;
}

// Push down the global navigation below the header using a placeholder element
.app-header-placeholder {
  // A note on Design Tokens in Sass:
  //
  // $height-header is a Sass variable pointing to a Design Token
  // Design tokens are listed at:
  // https://www.lightningdesignsystem.com/design-tokens/
  //
  // This is how you leverage Design Tokens in Sass:
  height: $height-header;
}

// Wrap the prototype in a scrollable container
.app-wrapper {
  overflow: auto;
  position: absolute;
  -webkit-overflow-scrolling: touch;
  top: $height-context-bar + $height-header;
  bottom: 0;
  right: 0;
  left: 0;
}

.proto-body {
  height: 100vh;
  background-image: url(/assets/hax/builder-custom.png);
  background-repeat: no-repeat;
  background-size: 100%;

  .proto-state-show-variants & {
    background-image: url(/assets/hax/button-variants.png);
  }
  .proto-state-show-guidelines & {
    background-image: url(/assets/hax/builder-drawer.png);
  }
  .proto-state-add-sales-by-region & {
    // background-image: url(/assets/hax/add-sales-by-region.png);
  }
}

.proto-left-col {
  width: 22%;
}
.proto-center-col {
  width: auto;
}
.proto-right-col {
  width: 19.5%;
}

.proto-invisible-button {
  background: none;
  border: 2px solid blue;
  border-radius: 0.25rem;
  opacity: 0;
}
.proto-floating {
  position: absolute;
}
.proto-cover-up {
  background-color: white;
}

.background-starter {
  background-size: 1600px;
  background-image: url(/assets/hax/builder-starter.png);
}

.background-show-variants {
  background-size: 1600px;
  background-image: url(/assets/hax/button-variants.png);
}

.background-show-carousel {
  background-size: 1600px;
  background-image: url(/assets/hax/button-variants.png);
}

body {
  position: relative;
  width: 1600px;
}